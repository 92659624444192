<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Laboratoires Associés de Radiophysique et de Dosimétrie</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2019</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Refonte graphique, design, communication
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Lors de notre second semestre en Métiers du Multimédia et de
						l'Internet, les Laboratoires Associés de Radiophysique et de
						Dosimétrie ont fait une demande de refonte graphique complète au
						DUT. Ainsi, nous avons eu la possibilité de voir tous les principes
						d'une refonte graphique complète à travers ce projet en passant de
						la phase d'analyse théorique à la phase pratique. <br /><br />J'ai
						alors proposé divers éléments à savoir un logo réalisé sur Adobe
						Illustrator accompagné d'une plaquette promotionnelle faite sur
						Adobe InDesign et de la maquette de la page d'accueil créée sur
						Adobe XD. Pour finir j'ai réalisé un dossier complet reprenant
						toutes mes recherches sur l'entreprise, ses concurrents et mes
						recherches sur le logo.
					</p>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a
							href="https://antoninwinterstein.com/Refonte_logo_LARD.pdf"
							class="boutonVoirSite"
							>VOIR LE DOSSIER COMPLET DU PROJET</a
						>
					</div>

					<!--Image 1-->

					<img
						src="../assets/lard/recherches_papier.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Recherches papier du logo"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Recherches papier du logo
					</p>

					<!--Image 2-->

					<img
						src="../assets/lard/logos.svg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Logo de base du L.A.R.D en haut,
                mes versions finales numériques
                du logo en couleur à
                gauche, en niveaux de gris au centre et en noir et blanc à droite"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Logo de base du L.A.R.D en haut, mes versions finales numériques du
						logo en couleur à gauche, en niveaux de gris au centre et en noir et
						blanc à droite
					</p>

					<!--Image 3-->

					<img
						src="../assets/lard/plaquette1.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Première partie de la plaquette réalisée
                sur InDesign"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Première partie de la plaquette réalisée sur InDesign
					</p>

					<!--Image 4-->

					<img
						src="../assets/lard/plaquette2.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Seconde partie de la plaquette réalisée
                sur InDesign"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Seconde partie de la plaquette réalisée sur InDesign
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | L.A.R.D",
			meta: [
				{
					name: "description",
					content:
						"Découvrez le projet de refonte graphique du L.A.R.D réalisé par Antonin Winterstein !",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "L.A.R.D - REFONTE GRAPHIQUE",
				urlImage: require("../assets/lard/fond_lard.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
